import { DiffEditor } from '@monaco-editor/react';
import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select } from '@mui/material';
import * as monaco from 'monaco-editor';
import React, { useRef, useState } from 'react';
import { DataDto } from '../api/dtos/data.interface';

interface DataDiffDialogProps {
    onClose: () => void;
    versions: DataDto[];
    language?: string;
}

const DataDiffDialog: React.FC<DataDiffDialogProps> = ({ onClose, versions, language }) => {
    const [selectedOriginalVersion, setSelectedOriginalVersion] = useState(versions[0]?.promptVersion);
    const [selectedModifiedVersion, setSelectedModifiedVersion] = useState(versions[versions.length - 1]?.promptVersion);

    const originalEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
    const modifiedEditorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

    React.useEffect(() => {
        originalEditorRef.current?.setValue(versions.find((version) => version.promptVersion === selectedOriginalVersion)?.outputData || '');
    }, [versions, selectedOriginalVersion]);

    React.useEffect(() => {
        modifiedEditorRef.current?.setValue(versions.find((version) => version.promptVersion === selectedModifiedVersion)?.outputData || '');
    }, [versions, selectedModifiedVersion]);

    return (
        <Dialog
            open={versions.length > 1}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                Compare Versions
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ height: '70vh', p: 0 }}>
                {versions.length > 1 && (
                    <Box sx={{ display: 'flex', gap: 2, p: 2 }}>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                defaultValue={versions[0].promptVersion}
                                value={selectedOriginalVersion}
                                onChange={(e) => setSelectedOriginalVersion(e.target.value)}
                            >
                                {versions.map((version) => (
                                    <MenuItem key={version.promptVersion} value={version.promptVersion}>
                                        {version.promptVersion}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <Select
                                size="small"
                                defaultValue={versions[versions.length - 1].promptVersion}
                                value={selectedModifiedVersion}
                                onChange={(e) => setSelectedModifiedVersion(e.target.value)}
                            >
                                {versions.map((version) => (
                                    <MenuItem key={version.promptVersion} value={version.promptVersion}>
                                        {version.promptVersion}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <DiffEditor
                    height="100%"
                    language={language ?? 'plaintext'}
                    onMount={(editor, monaco) => {
                        originalEditorRef.current = editor.getOriginalEditor();
                        modifiedEditorRef.current = editor.getModifiedEditor();
                        originalEditorRef.current?.setValue(versions[0].outputData);
                        modifiedEditorRef.current?.setValue(versions[versions.length - 1].outputData);
                    }}
                    options={{
                        wordWrap: 'on',
                        readOnly: true,
                        renderSideBySide: true,
                        minimap: { enabled: false }
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};

export default DataDiffDialog;
