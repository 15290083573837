import { CompareArrows } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { PromptDto } from '../../api/dtos/prompt.interface';

interface PromptVersionPaneProps {
    promptVersions: PromptDto[];
    editedVersions: { [key: string]: PromptDto };
    onCompare: (version?: string) => void;
    onSelect: (version: string) => void;
    onChangeVersionName: (originalName: string, newName: string) => void;
    selectedVersion: string;
    selectedCompareVersion?: string;
}

export default function PromptVersionPane({ promptVersions, editedVersions, onCompare, onSelect, onChangeVersionName, selectedVersion, selectedCompareVersion }: PromptVersionPaneProps) {
    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Fixed header section */}
            <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0' }}>
                <Typography variant="h6">{promptVersions.find(p => p.__version === selectedVersion)?.agentName ?? selectedVersion}</Typography>
            </Box>

            {/* Scrollable content section */}
            <Box sx={{ flex: 1, overflow: 'auto', p: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {promptVersions.map((version) => {
                        const handleSelect = (e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<HTMLElement>
                        ) => {
                            if (version.__version === selectedVersion) return;
                            e.stopPropagation();
                            e.preventDefault();
                            onCompare(undefined);
                            onSelect(version.__version!);
                        }

                        const handleDoubleClick = (e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<HTMLElement>) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (version.__version === 'latest') {
                                alert('Cannot rename latest version');
                                return;
                            }
                            const newVersion = prompt('Enter new version name:', version.__version!);
                            if (!newVersion) return;
                            if (newVersion.length === 0) {
                                alert('Version name cannot be empty');
                                return;
                            }

                            if (promptVersions.find(v => version.__version !== v.__version && ((v.__version === newVersion && !editedVersions[v.__version]) || editedVersions[v.__version!]?.__version === newVersion))) {
                                alert('Version name already exists');
                                return;
                            }
                            onChangeVersionName(version.__version!, newVersion);
                        }

                        return (
                            <Box
                                key={version.__version}
                                sx={{
                                    p: 2,
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 1,
                                    '&:hover': {
                                        backgroundColor: selectedCompareVersion === version.__version ? '#c8e6c9' : selectedVersion === version.__version ? '#bbdefb' : '#eeeeee'
                                    },
                                    position: 'relative',
                                    cursor: 'pointer',
                                    backgroundColor: selectedCompareVersion === version.__version ? '#e8f5e9' : selectedVersion === version.__version ? '#e3f2fd' : 'transparent'
                                }}
                                onClick={handleSelect}
                                onDoubleClick={handleDoubleClick}
                            >
                                {editedVersions[version.__version!] && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 8,
                                            right: 8,
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: '#1976d2'
                                        }}
                                    />
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={handleSelect} onDoubleClick={handleDoubleClick}>
                                    <Typography sx={{ cursor: 'pointer' }} onClick={handleSelect}>{editedVersions[version.__version!] ? editedVersions[version.__version!].__version : version.__version}</Typography>
                                    <IconButton
                                        sx={{ position: 'absolute', top: 8, right: 12, display: 'flex', gap: 2 }}
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (version.__version !== selectedCompareVersion) {
                                                onCompare(version.__version!);
                                            } else {
                                                onCompare(undefined);
                                            }
                                        }} >
                                        <CompareArrows />
                                    </IconButton>
                                </Box>
                                <Typography variant="caption" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={handleSelect} onDoubleClick={handleDoubleClick}>
                                    Last updated: {version.__updatedAt ? new Date(version.__updatedAt).toLocaleString() : 'Unknown'}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Box>

            {/* Fixed footer section */}
            <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                        const versionName = window.prompt('Enter a version name:');
                        if (!versionName) return;
                        onSelect(versionName);
                    }}
                >
                    Create New Version
                </Button>
            </Box>
        </Box>
    );
}

