import { ChevronLeft, Logout } from '@mui/icons-material';
import { AppBar, AppBarProps, Box, CSSObject, Divider, Drawer, IconButton, List, styled, Theme, Toolbar, Typography } from '@mui/material';
import { Navigate, RouterProvider } from 'react-router';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import { ToolbaseApi } from './api/toolbase.api';
import './App.css';
import { AgentsListItem } from './menu/agents-list-item';
import { SettingsListItem } from './menu/settings-list-item';
import { ToolsListItem } from './menu/tools-list-item';
import { AgentDetailsWrapper } from './pages/agents-details';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';

const isLoggedIn = ToolbaseApi.auth.isLoggedIn();

const authenticatedRoutes = [
  {
    path: "/",
    element: <Navigate to="/agents" />,
  },
  {
    path: "/agents",
    element: <Outlet />,
    children: [
      {
        path: ":namespace/:name/:version?",
        element: <AgentDetailsWrapper />,
      }
    ]
  },
  {
    path: "/tools",
    element: <Outlet />,
    children: [
      {
        path: ":namespace/:name/:version?",
        element: <AgentDetailsWrapper />,
      }
    ]
  },
];

const unauthenticatedRoutes = [
  {
    path: "*",
    element: <Navigate to="/login" />,
  },
  {
    path: "/login",
    element: isLoggedIn ? <Navigate to="/" /> : <LoginPage />,
  },
  {
    path: "/register",
    element: isLoggedIn ? <Navigate to="/" /> : <RegisterPage />,
  },
];

const router = createBrowserRouter(isLoggedIn ? authenticatedRoutes : unauthenticatedRoutes);

const drawerWidth = 360;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface MyAppBarProps extends AppBarProps {
  open?: boolean;
}

const MyAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<MyAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MyDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {
  return (
    <Box sx={{ display: 'flex' }}>
      {isLoggedIn && (
        <>
          <MyAppBar position="fixed" open={false}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                // onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  // ...(isMenuOpen && { display: 'none' }),
                  ...({ visibility: 'hidden' })
                }}>
              </IconButton>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                Toolbase
              </Typography>
              <IconButton
                color="inherit"
                onClick={() => {
                  ToolbaseApi.auth.logout();
                  window.location.href = '/';
                }}
              >
                <Logout />
              </IconButton>
            </Toolbar>
          </MyAppBar>
          <MyDrawer variant="permanent" open={false}>
            <DrawerHeader>
              <Typography variant="h6" noWrap component="div" sx={{ width: '100%' }}>
                Toolbase
              </Typography>
              <IconButton>
                <ChevronLeft />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <AgentsListItem isCompacted={true} drawerHeader={DrawerHeader} width={drawerWidth} />
              {/* <ToolsListItem isCompacted={true} drawerHeader={DrawerHeader} width={drawerWidth} /> */}
              <SettingsListItem isCompacted={true} />
            </List>
          </MyDrawer>
        </>
      )}
      <Box component="main" sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        overflow: 'auto'
      }}>
        {isLoggedIn && <DrawerHeader />}
        <RouterProvider router={router} />
      </Box>
    </Box>
  );
}

export default App;
