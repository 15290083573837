import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import React, { ErrorInfo } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ErrorBoundary } from './error-boundary';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';
import { Editor } from '@monaco-editor/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const onError = (error: Error, errorInfo: ErrorInfo) => {
  console.error('Error:', error);
  console.error('Error Info:', errorInfo);
  //TODO: Log unhandled error to error reporting service here
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary onError={onError}>
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
