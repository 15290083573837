import { TextField } from "@mui/material";
import { Form } from "@rjsf/mui";
import validator from '@rjsf/validator-ajv8';
import { JSONSchema7 } from "json-schema";
import { Component, ReactNode } from "react";
import Markdown from "react-markdown";


export class MessageBubble extends Component {

    constructor(readonly props: {
        content: string | { [key: string]: any },
        contentFormat?: JSONSchema7,
        onAssistantMessageChange: (value: string | { [key: string]: any }) => void,
        isStreaming: boolean,
        editable: boolean,
    }) {
        super(props);
    }

    render(): ReactNode {

        // console.log(this.props.content);
        // console.log(this.props.contentFormat);

        return (
            <div className='MessageBubble'>
                {this.props.editable && this.props.contentFormat ? (
                    <Form
                        schema={this.props.contentFormat}
                        uiSchema={{
                            "ui:submitButtonOptions": { norender: true },
                        }}
                        formData={this.props.content}
                        validator={validator}
                        onChange={(data) => this.props.onAssistantMessageChange(data.formData)}
                    />
                ) : this.props.editable && !this.props.contentFormat ? (
                    <TextField
                        multiline
                        fullWidth
                        value={typeof this.props.content === 'string' ? this.props.content : JSON.stringify(this.props.content, null, 2)}
                        variant="outlined"
                        minRows={3}
                        onChange={(e) => this.props.onAssistantMessageChange(e.target.value)}
                    />
                ) : (
                    <Markdown>{typeof this.props.content === 'string' ? this.props.content : ('```json\n' + JSON.stringify(this.props.content, null, 2) + (this.props.isStreaming ? '\n...' : '') + '\n```')}</Markdown>
                )}
            </div>
        );

    }
}