import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#222222', // Dark neutral (charcoal) for primary
            light: '#3d3d3d',
            dark: '#0d0d0d',
            contrastText: '#ffffff', // White text for contrast
        },
        secondary: {
            main: '#eaeaea', // Light neutral (off-white) for secondary
            light: '#f5f5f5',
            dark: '#bdbdbd',
            contrastText: '#1f1f1f', // Dark text for contrast
        },
        background: {
            default: '#fafafa', // Very light grey background (near white)
            paper: '#ffffff', // Paper elements are pure white
        },
        text: {
            primary: '#111111', // Nearly black for sharp readability
            secondary: '#737373', // Medium grey for secondary text
        },
        divider: '#e0e0e0', // Light grey for dividers
    },
    typography: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        body1: {
            color: '#111111', // Dark, clean text color
            fontWeight: 400,
        },
        body2: {
            color: '#737373', // Lighter grey for secondary content
            fontWeight: 400,
        },
        h1: {
            color: '#1f1f1f', // Dark for heading contrast
            fontWeight: 700, // Bold for ShadCN-like emphasis
        },
        h2: {
            color: '#1f1f1f',
            fontWeight: 600,
        },
        button: {
            textTransform: 'none', // Avoid all-caps buttons, similar to ShadCN
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '6px', // More subtle radius for a sharp look
                    padding: '8px 16px',
                    textTransform: 'none', // No uppercase, more like ShadCN's style
                    boxShadow: 'none', // Remove button shadows for flat design
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#ffffff', // Pure white for a clean look
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)', // Light shadow for depth
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222222', // White app bar background
                    color: '#ffffff', // Dark text for app bar elements
                    boxShadow: 'none', // Minimal shadows for a flat, clean look
                    borderBottom: '1px solid #e0e0e0', // Subtle bottom border for separation
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    backgroundColor: '#f5f5f5', // Light neutral input background
                    borderRadius: '4px', // Slightly rounded inputs
                    padding: '8px',
                    // border: '1px solid #e0e0e0', // Subtle border for clean forms
                    // '&:focus': {
                    //     borderColor: '#1f1f1f', // Dark border on focus
                    // },
                },
            },
        },
    },
});

export default theme;