
import { Settings } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import React, { startTransition, useState } from 'react';
import { ToolbaseApi } from '../api/toolbase.api';

interface SettingsListItemProps {
    isCompacted: boolean;
}

export const SettingsListItem: React.FC<SettingsListItemProps> = ({ isCompacted }) => {
    const [open, setOpen] = useState(false);
    const [apiToken, setApiToken] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (event: React.FormEvent) => {
        event.preventDefault();
        ToolbaseApi.auth.generateApiToken(username, password)
            .then(token => {
                setApiToken(token);
            })
            .catch(error => {
                alert(`Failed to generate token: ${error.message}`);
                return false;
            });
    }

    const handleClose = () => {
        startTransition(() => {
            setUsername('');
            setPassword('');
            setApiToken(undefined);
            setOpen(false);
        });
    }

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                onClick={() => setOpen(true)}
                sx={{
                    minHeight: 48,
                    justifyContent: !isCompacted ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: !isCompacted ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    <Settings />
                </ListItemIcon>
                <ListItemText primary="Settings" sx={{ opacity: !isCompacted ? 1 : 0 }} />
            </ListItemButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Generate API Token</DialogTitle>
                <DialogContent>
                    {!apiToken ? (
                        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    ) : (
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">Your API Token:</Typography>
                            <TextField
                                margin="normal"
                                fullWidth
                                value={apiToken}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </ListItem>
    );
};
