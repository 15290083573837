import { CompareArrows } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, MenuItem, Select, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import { DataDto } from '../api/dtos/data.interface';
import { PromptDto } from '../api/dtos/prompt.interface';
import DataDiffDialog from './data-diff-dialog';

interface DataPaneProps {
    data: DataDto[];
    promptVersions: PromptDto[];
    promptVersion: string;
    caliber?: 'GOLD' | 'SILVER' | 'BRONZE';
    onReplay?: (data: DataDto) => void;
    onDelete?: (data: DataDto) => void;
    onMove?: (version: string, newCaliber: 'GOLD' | 'SILVER' | 'BRONZE') => void;
    onPromptVersionChange?: (promptVersion: string) => void;
}

const DataPane: React.FC<DataPaneProps> = ({ data, promptVersions, promptVersion, onReplay, onDelete, onMove, onPromptVersionChange, ...defaults }) => {
    const [selectedDataCaliber, setSelectedDataCaliber] = useState<'GOLD' | 'SILVER' | 'BRONZE'>(defaults.caliber ?? 'GOLD');
    const [selectedDataInputData, setSelectedDataInputData] = useState(undefined as string | undefined);

    const [dataHash, goldData, silverData, bronzeData] = React.useMemo(() => {
        const hash = new Map<string, DataDto[]>();
        const gold: DataDto[] = [];
        const silver: DataDto[] = [];
        const bronze: DataDto[] = [];
        data.forEach(d => {
            hash.set(d.inputData.trim(), [...(hash.get(d.inputData.trim()) ?? []), d]);
            if (d.caliber === 'GOLD') {
                gold.push(d);
            } else if (d.caliber === 'SILVER') {
                silver.push(d);
            } else {
                bronze.push(d);
            }
        });
        return [hash, gold, silver, bronze];
    }, [data]);

    const [directMatch, indirectMatch] = React.useMemo(() => {
        const result: [DataDto[], DataDto[]] = [[], []];
        const selectedData = selectedDataCaliber === 'GOLD' ? goldData : selectedDataCaliber === 'SILVER' ? silverData : bronzeData;
        for (const d of selectedData) {
            const inputData = d.inputData.trim();
            if (d.promptVersion === promptVersion) {
                result[0].push(d);
            } else {
                if (!selectedData.find(a => a.inputData.trim() === inputData && a.promptVersion !== d.promptVersion)) {
                    result[1].push(d);
                }
            }
        }
        return result;
    }, [data, promptVersion, selectedDataCaliber]);

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* Fixed header section */}
            <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0', paddingBottom: 0, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6">{promptVersions.find(p => p.__version === promptVersion)?.agentName ?? promptVersion}</Typography>
                <FormControl fullWidth>
                    <Select
                        value={promptVersion}
                        onChange={(e) => onPromptVersionChange?.(e.target.value)}
                    >
                        {promptVersions.map((version) => (
                            <MenuItem key={version.__version} value={version.__version}>
                                {version.__version}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Tabs
                    value={selectedDataCaliber}
                    onChange={(event, newValue) => setSelectedDataCaliber(newValue)}
                    sx={{}}>
                    <Tab label="Gold" value="GOLD" />
                    <Tab label="Silver" value="SILVER" />
                    <Tab label="Bronze" value="BRONZE" />
                </Tabs>
            </Box>

            {/* Scrollable content section */}
            <Box sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>

                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
                    {directMatch.length === 0 && indirectMatch.length === 0 && (
                        <Box sx={{ p: 2, textAlign: 'center' }}>
                            <Typography variant="body1" color="text.secondary">
                                No data available
                            </Typography>
                        </Box>
                    )}

                    {(directMatch.length > 0 || indirectMatch.length > 0) && [...directMatch, ...(indirectMatch.length > 0 ? [SEPERATOR] : []), ...indirectMatch].map((item, index) => {
                        if (item === SEPERATOR) {
                            return <Box key={index} sx={{ mb: 2, p: 2, borderTop: '1px solid #e0e0e0' }}>
                                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                                    Other Prompt Versions
                                </Typography>
                            </Box>;
                        }

                        return (
                            <Box key={index} sx={{ mb: 1, p: 2, border: '1px solid #e0e0e0', borderRadius: 2, position: 'relative' }}>
                                <Typography variant="subtitle1" gutterBottom noWrap>
                                    {item.inputData}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom noWrap>
                                    {item.outputData}
                                </Typography>
                                {(dataHash.get(item.inputData.trim())?.length ?? 0) > 1 && (
                                    <IconButton
                                        sx={{ position: 'absolute', top: 8, right: 12, display: 'flex', gap: 2 }}
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const versions = dataHash.get(item.inputData.trim());
                                            if (!versions) {
                                                return;
                                            }
                                            setSelectedDataInputData(item.inputData.trim());
                                        }} >
                                        <CompareArrows />
                                    </IconButton>)}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                            onDelete?.(item);
                                        }}>
                                        Delete
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            const newCaliber = window.prompt('Enter new caliber (GOLD, SILVER, or BRONZE):', item.caliber)?.toUpperCase();
                                            if (!newCaliber) {
                                                return;
                                            } else if (!['GOLD', 'SILVER', 'BRONZE'].includes(newCaliber)) {
                                                alert('Invalid caliber');
                                                return;
                                            } else if (newCaliber === item.caliber) {
                                                return;
                                            }
                                            onMove?.(item.__version!, newCaliber as 'GOLD' | 'SILVER' | 'BRONZE');
                                        }}
                                    >
                                        Move
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => onReplay?.(item)}>
                                        Replay
                                    </Button>
                                </Box>
                            </Box>
                        );
                    })}

                </Box>
            </Box>

            {/* Fixed footer button */}
            <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>

                <Button
                    variant="outlined"
                    fullWidth
                    disabled
                    sx={{ display: 'none' }}//unhide in new version, de-scoped for now
                >
                    Load Data
                </Button>
            </Box>

            {/* DataDiffDialog remains unchanged */}
            <DataDiffDialog
                onClose={() => setSelectedDataInputData(undefined)}
                versions={selectedDataInputData !== undefined && (dataHash.get(selectedDataInputData.trim())?.length ?? 0) > 1 ? (dataHash.get(selectedDataInputData.trim())?.sort((a, b) => new Date(b.__updatedAt!).getTime() - new Date(a.__updatedAt!).getTime()) ?? []) : []}
                language={selectedDataInputData?.trim().startsWith('{') && selectedDataInputData?.trim().endsWith('}') ? 'json' : 'plaintext'}
            />
        </Box>
    );
};

const SEPERATOR: DataDto = {
    inputData: '',
    outputData: '',
    promptVersion: '',
    caliber: 'GOLD',
    agentNamespace: '',
    agentName: '',
    tags: '',
    reasoning: [],
};

export default DataPane;

// Add this function outside of the component or use React.useMemo for performance
const renderCellValue = (value: any): React.ReactNode => {
    if (value === null || value === undefined) return '';
    if (typeof value === 'object') return JSON.stringify(value);
    return String(value);
};
