import "@assistant-ui/react/styles/index.css";
import { Alert, Box, Snackbar, Tab, Tabs } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DataDto } from "../api/dtos/data.interface";
import { PromptDto } from "../api/dtos/prompt.interface";
import { ToolbaseApi } from "../api/toolbase.api";
import Chat from "../components/chat/chat";
import DataPane from "../components/data-pane";
import PromptEditor from "../components/prompt-editor/prompt-editor";
import PromptVersionPane from "../components/prompt-editor/prompt-version-pane";


type AgentDetailsProps = {
    readonly namespace: string;
    readonly name: string;
    readonly version?: string;
    readonly caliber?: 'GOLD' | 'SILVER' | 'BRONZE';
    readonly promptVersions?: PromptDto[];
    readonly data?: DataDto[];
}

export function AgentDetailsWrapper(props: Partial<AgentDetailsProps>) {
    let { namespace, name, version, caliber } = useParams();
    if (!name || !namespace) throw new Error('Agent name or namespace not defined.');
    if (caliber && (caliber !== 'GOLD' && caliber !== 'SILVER' && caliber !== 'BRONZE')) throw new Error('Invalid caliber.');

    return <AgentDetails namespace={namespace} name={name} version={version} caliber={caliber as 'GOLD' | 'SILVER' | 'BRONZE'} />;
}

export function AgentDetails({ namespace, name, ...props }: AgentDetailsProps) {
    const [selectedVersion, setSelectedVersion] = useState<string>(props.version ?? 'latest');
    const [selectedCompareVersion, setSelectedCompareVersion] = useState<string | undefined>(undefined);

    const [versions, setVersions] = useState<PromptDto[] | undefined>(props.promptVersions);
    const [data, setData] = useState<DataDto[] | undefined>(props.data);

    const [tabIndex, setTabIndex] = useState(0);

    const [_, rerender] = useState(false);

    const currentDataVersion = useRef<string | undefined>(undefined);
    const messages = useRef<(string | { [key: string]: any })[] | undefined>(undefined);
    const [savedMessages, setSavedMessages] = useState<typeof messages.current>([]);

    useEffect(() => {
        const fetchAll = async () => {
            const [versions, data] = await Promise.all([
                ToolbaseApi.agents.listPromptVersions(namespace!, name!),
                ToolbaseApi.data.list(namespace!, name!)
            ]);
            React.startTransition(() => {
                setVersions(versions);
                setData(data);
                setSelectedVersion(versions[0].__version!);
            });
        };

        fetchAll();
    }, [namespace, name]);

    const [snackbar, setSnackbar] = useState<{
        message: string;
        severity: 'success' | 'error' | 'warning' | 'info';
    } | undefined>();

    const editedVersions = useRef<{ [key: string]: PromptDto & { isNew: boolean } }>({});

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (Object.keys(editedVersions.current).length > 0) {
                event.preventDefault();
                return 'You have unsaved changes. Are you sure you want to leave?';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const selectedPrompt = editedVersions.current[selectedVersion] ?? versions?.find(p => p.__version === selectedVersion);
    const comparePrompt = selectedCompareVersion ? (selectedCompareVersion === selectedVersion ? versions?.find(p => p.__version === selectedVersion) : (editedVersions.current[selectedCompareVersion] ?? versions?.find(p => p.__version === selectedCompareVersion))) : undefined;

    const changeTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
        React.startTransition(() => {
            setSavedMessages(messages.current);
            setTabIndex(newValue);
        });
    }, []);

    const onReplayData = useCallback((replayData: DataDto) => {
        let inputData = replayData.inputData;
        let outputData = replayData.outputData;
        if (selectedPrompt.inputParameters && selectedPrompt.inputParameters.length > 0 && inputData.startsWith('{') && inputData.endsWith('}')) {
            try {
                inputData = JSON.parse(inputData);
            } catch (e) { }
        }
        if (selectedPrompt.outputParameters && selectedPrompt.outputParameters.length > 0 && outputData.startsWith('{') && outputData.endsWith('}')) {
            try {
                outputData = JSON.parse(outputData);
            } catch (e) { }
        }
        
        currentDataVersion.current = replayData.promptVersion === selectedVersion ? replayData.__version : data?.find(d => d.inputData.trim() === inputData.trim() && d.promptVersion === selectedVersion)?.__version;
        messages.current = [inputData, outputData];
        setSavedMessages([inputData, outputData]);

        // if (data.promptVersion === selectedVersion) {
        //     messages.current = [inputData, outputData];
        //     setSavedMessages([inputData, outputData]);
        // } else {
        //     messages.current = [inputData];
        //     setSavedMessages([inputData]);
        // }
    }, [data, selectedPrompt, selectedVersion]);

    const onDeleteData = useCallback((data: DataDto) => {
        if (!window.confirm('Are you sure you want to delete this data?')) {
            return;
        }
        ToolbaseApi.data.delete(namespace, name, data.promptVersion, data.__version!).then(() => {
            setData(prevData => prevData?.filter(d => d !== data));
        }).catch(error => {
            console.error('Error deleting data:', error);
            alert('Error deleting data: ' + error.message);
        });
    }, [namespace, name]);

    const onMoveData = useCallback((version: string, newCaliber: 'GOLD' | 'SILVER' | 'BRONZE') => {
        ToolbaseApi.data.update(namespace, name, selectedVersion, version, { caliber: newCaliber as 'GOLD' | 'SILVER' | 'BRONZE' })
            .then((savedData) => {
                setData(prevData => {
                    return prevData?.map(d => version === d.__version ? savedData : d)
                });
            })
            .catch(error => {
                console.error('Error moving data:', error);
                alert('Error moving data: ' + error.message);
            });
    }, [namespace, name, selectedVersion]);

    const onNewData = useCallback((version: string | undefined, newData: Pick<DataDto, 'inputData' | 'outputData' | 'reasoning' | 'caliber' | 'tags'>) => {
        version = version ?? currentDataVersion.current;
        currentDataVersion.current = undefined;
        if (version) {
            ToolbaseApi.data.update(namespace, name, selectedVersion, version, newData).then((savedData) => {
                setData(prevData => {
                    const foundData = prevData?.find(d => d.inputData.trim() === savedData.inputData.trim() && d.promptVersion === savedData.promptVersion);
                    if (!foundData) {
                        return [savedData, ...prevData!];
                    } else {
                        return prevData?.map(d => d.inputData.trim() === savedData.inputData.trim() && d.promptVersion === savedData.promptVersion ? savedData : d);
                    }
                });
                messages.current = [];
            }).catch(error => {
                alert('Failed to save data: ' + error.message);
            });
        } else {
            ToolbaseApi.data.create(namespace, name, selectedVersion, { ...{ agentNamespace: namespace, agentName: name, promptVersion: selectedVersion }, ...newData }).then((savedData) => {
                setData(prevData => {
                    const foundData = prevData?.find(d => d.__version === savedData.__version);
                    if (!foundData) {
                        return [savedData, ...prevData!];
                    } else {
                        return prevData?.map(d => d.__version === savedData.__version ? savedData : d);
                    }
                });
                messages.current = [];
            }).catch(error => {
                alert('Failed to save data: ' + error.message);
            });
        }
    }, [namespace, name, selectedVersion]);

    const onDeletePrompt = useCallback(() => {
        if (versions?.length === 1) {
            if (window.confirm('WARNING:\n\nThis will delete the agent and all associated data. Are you sure you want to delete this agent?')) {
                ToolbaseApi.agents.deleteAgent(namespace, name).then(() => {
                    editedVersions.current = {};
                    window.location.href = '/';
                }).catch(error => {
                    alert('Error deleting agent: ' + error.message);
                });
            }
        } else {
            if (selectedPrompt.__version === 'latest') {
                alert('You cannot delete the \'latest\' prompt version until you have deleted all other versions.');
                return;
            }

            if (!window.confirm(`Are you sure you want to delete prompt version ${selectedPrompt.__version}?`)) {
                return;
            }

            (!selectedPrompt.isNew ? ToolbaseApi.agents.deletePromptVersion(namespace, name, selectedPrompt.__version!) : Promise.resolve()).then(() => {
                React.startTransition(() => {
                    delete editedVersions.current[selectedPrompt.__version!];
                    setSelectedVersion(versions?.filter(v => v.__version !== selectedPrompt.__version)[0].__version!);
                    setVersions(prevVersions => prevVersions?.filter(v => v.__version !== selectedPrompt.__version));
                });
            }).catch(error => {
                alert('Error deleting prompt: ' + error.message);
            });
        }
    }, [selectedPrompt, versions, setSelectedVersion, setVersions]);

    const onSavePrompt = useCallback(() => {
        const versionName = selectedVersion;
        if (!editedVersions.current[versionName]) return;

        const isNew = editedVersions.current[versionName].isNew;

        (isNew ? ToolbaseApi.agents.createPrompt(namespace, name, versionName, editedVersions.current[versionName]) : ToolbaseApi.agents.updatePrompt(namespace, name, versionName, editedVersions.current[versionName]))
            .then((r) => {
                delete editedVersions.current[versionName];
                React.startTransition(() => {
                    setVersions(versions?.map(v => v.__version === versionName ? r : v));
                    setSelectedVersion(r.__version!);
                    setSnackbar({
                        message: `Prompt ${isNew ? 'created' : 'updated'} successfully`,
                        severity: 'success'
                    });
                });
            })
            .catch(error => {
                alert(error.message);
                setSnackbar({
                    message: `Failed to ${isNew ? 'create' : 'update'} agent`,
                    severity: 'error'
                });
            });
    }, [versions, selectedVersion, namespace, name]);

    const onFork = useCallback(() => {
        let c = 1;
        let version = selectedVersion + ' ' + c++;
        while (versions?.find(v => v.__version === version || editedVersions.current[v.__version!]?.__version === version)) {
            version = selectedVersion + ' ' + c++;
        }
        const newVersion: PromptDto & { isNew: boolean } = {
            ...selectedPrompt,
            __version: version,
            isNew: true
        };

        React.startTransition(() => {
            editedVersions.current[version] = newVersion;
            setVersions(prevVersions => [newVersion, ...(prevVersions ?? [])]);
            setSelectedVersion(version);
        });
    }, [selectedVersion, selectedPrompt, versions]);

    const onChangeVersionName = useCallback((originalName: string, newName: string) => {
        rerender(prev => !prev);
        editedVersions.current[originalName] = { ...versions?.find(v => v.__version === originalName), ...(editedVersions.current[originalName] ?? {}), __version: newName };
    }, [versions]);

    const onCompareVersion = useCallback((version?: string) => {
        if (version === selectedVersion) {
            if (editedVersions.current[selectedVersion]) {
                setSelectedCompareVersion(version);
            } else {
                setSelectedCompareVersion(undefined);
            }
        } else {
            setSelectedCompareVersion(version);
        }
    }, [selectedVersion, editedVersions]);

    const onSelectVersion = useCallback((version: string) => {
        if (versions?.find(v => v.__version === version)) {
            setSelectedVersion(version);
        } else {
            let newVersion: PromptDto & { isNew: boolean } = {
                __version: version,
                agentNamespace: namespace,
                agentName: name,
                summary: '',
                prompt: 'Respond to the user in a friendly and helpful manner. Remind them that they must configure the prompt before it can be used.',
                modelProvider: selectedPrompt.modelProvider,
                modelId: selectedPrompt.modelId,
                isNew: true
                //                 outputMessageTemplate: `
                // async function assertOrTransform(user: User, input: Input, output: Output, similarExamples: SimilarExample[]): Promise<Output> {
                // //add your code here
                // return output; 
                // }
                //                 `.trim()
            };
            React.startTransition(() => {
                editedVersions.current[version] = newVersion;
                setVersions(prevVersions => [newVersion, ...(prevVersions ?? [])]);
                setSelectedVersion(version);
            });
        }
    }, [versions, selectedPrompt, namespace, name]);

    const onChangePrompt = useCallback((changes: Partial<PromptDto>) => {
        if (!editedVersions.current[selectedVersion]) {
            rerender(prev => !prev);
        }
        editedVersions.current[selectedVersion] = { ...selectedPrompt, ...(editedVersions.current[selectedVersion] ?? {}), __version: selectedVersion, ...changes };
    }, [selectedVersion, selectedPrompt]);

    if (!versions || !data || !selectedPrompt) {
        return <Box>Loading...</Box>;
    }

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={changeTab} aria-label="basic tabs example">
                    <Tab label="Evaluate" {...a11yProps(0)} />
                    {/* <Tab label="|" disabled sx={{ minWidth: 50 }} /> */}
                    <Tab label="Prompt" {...a11yProps(1)} />
                    <Tab label="Input" {...a11yProps(2)} />
                    <Tab label="Output" {...a11yProps(3)} />

                </Tabs>
            </Box>
            <CustomTabPanel value={tabIndex} index={0}>
                <Box component="main" sx={{
                    flexGrow: 1,
                    display: 'flex',
                    height: '100%',
                    overflow: 'hidden'
                }}>
                    <Box sx={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
                        <Chat prompt={selectedPrompt} evaluating={true} messages={savedMessages} onNewData={onNewData} messageStream={(msgState) => messages.current = msgState} />
                    </Box>
                    <Box sx={{ width: '25%', borderLeft: '1px solid #e0e0e0', p: 2, overflow: 'auto' }}>
                        <DataPane data={data} promptVersions={versions} promptVersion={selectedVersion} onReplay={onReplayData} onDelete={onDeleteData} onMove={onMoveData} onPromptVersionChange={(promptVersion) => setSelectedVersion(promptVersion)} />
                    </Box>
                </Box>
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={Math.max(1, tabIndex)}>
                <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '75%' }}>
                        <PromptEditor
                            prompt={editedVersions.current[selectedVersion] ?? selectedPrompt}
                            display={(['system', 'input', 'output'][tabIndex - 1] ?? 'system') as 'system' | 'input' | 'output'}
                            comparePrompt={comparePrompt}
                            onChange={onChangePrompt}
                            onFork={onFork}
                            onDelete={onDeletePrompt}
                            onSave={onSavePrompt}
                        />
                    </Box>
                    <Box sx={{ width: '25%', borderLeft: '1px solid #e0e0e0', p: 2, overflow: 'auto' }}>
                        <PromptVersionPane
                            promptVersions={versions}
                            editedVersions={editedVersions.current}
                            selectedVersion={selectedVersion}
                            selectedCompareVersion={selectedCompareVersion}
                            onChangeVersionName={onChangeVersionName}
                            onCompare={onCompareVersion}
                            onSelect={onSelectVersion} />
                    </Box>
                </Box>
            </CustomTabPanel>
            <Snackbar
                open={snackbar !== undefined}
                autoHideDuration={3000}
                onClose={() => setSnackbar(undefined)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    severity={snackbar?.severity}
                    onClose={() => setSnackbar(undefined)}
                >
                    {snackbar?.message}
                </Alert>
            </Snackbar>
        </>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: value === index ? 'block' : 'none',
            overflow: index === 0 ? 'hidden' : 'auto'
        }}
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
