import { Save, Send, Stop } from '@mui/icons-material';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Form } from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { JSONSchema7 } from 'json-schema';
import { useEffect, useState } from 'react';
import { PromptDto } from '../../api/dtos/prompt.interface';
import './chat.css';


interface ChatInputState {
    input: string | { [key: string]: any };
    showDialog?: boolean;
    selectedPrompt?: PromptDto;
}

interface ChatInputProps {
    isIdle: boolean,
    onNewMessage: (message: string | { [key: string]: any }, prompt?: PromptDto) => void;
    onStop: () => void;
    autoFocus?: boolean;
    inputSchema?: JSONSchema7;
    availablePrompts?: PromptDto[];
    evaluating?: boolean;
    initialInput: string | { [key: string]: any };
    onSave?: () => void;
}

function ChatInput(props: ChatInputProps) {
    const [state, setState] = useState<ChatInputState>({
        input: props.inputSchema ? (props.initialInput ?? {}) : (props.initialInput ?? '')
    });

    useEffect(() => {
        setState({ ...state, input: props.inputSchema ? (props.initialInput) : (props.initialInput) });
    }, [props.initialInput]);

    const inputKeyUp = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            newMessage(state.input);
        }
    }

    const newMessage = (msg?: string | { [key: string]: any }) => {
        console.log(msg, state.input, props.isIdle);
        if (!props.isIdle || !msg) return;
        props.onNewMessage(msg ?? state.input, state.selectedPrompt);
        setState({ ...state, showDialog: false, input: '' });
    }

    const saveData = () => {
        if (!props.isIdle || state.input.length !== 0) return;
        props.onSave?.();
    }

    const _stop = () => {
        if (props.isIdle) return;
        props.onStop();
    }

    const handleInputChange = (event: any) => {
        setState(state => ({ ...state, input: event.target.value }));
    };

    return (
        <div className={"chat-input"}>
            {!props.inputSchema ? (
                <><input
                    className="chat-input-text"
                    type="text"
                    autoFocus={props.autoFocus}
                    onKeyUp={inputKeyUp}
                    placeholder="Enter your prompt"
                    value={state.input as string}
                    onChange={handleInputChange} />

                    <Button variant='contained' className="chat-input-button" onClick={props.isIdle ? (props.evaluating && state.input.length === 0 ? () => saveData() : () => newMessage(state.input)) : _stop} color={props.isIdle ? (props.evaluating && state.input.length === 0 ? 'info' : 'success') : 'error'}>{props.isIdle ? (props.evaluating && state.input.length === 0 ? <Save /> : <Send />) : <Stop />}</Button></>) : (
                <>
                    <Dialog
                        open={state.showDialog ?? false}
                        onClose={() => setState({ ...state, showDialog: false })}
                        onClick={(event) => event.stopPropagation()}
                    >
                        <DialogTitle>Input Form</DialogTitle>
                        <DialogContent>
                            <Form
                                schema={props.inputSchema}
                                formData={state.input as { [key: string]: any; }}
                                validator={validator}
                                autoComplete="off"
                                uiSchema={props.inputSchema?.properties && Object.keys(props.inputSchema.properties).length > 0 ? {
                                    "ui:submitButtonOptions": {
                                        "submitText": "Send",
                                    },
                                    [Object.keys(props.inputSchema.properties)[0]]: {
                                        'ui:autofocus': true,
                                    }
                                } : {
                                    "ui:submitButtonOptions": {
                                        "submitText": "Send",
                                    }
                                }}
                                onSubmit={(data) => {
                                    newMessage(data.formData);
                                }} />
                        </DialogContent>
                    </Dialog>
                    <Button
                        variant='contained'
                        className="chat-input-button"
                        onClick={props.isIdle ? () => setState({ ...state, showDialog: true }) : _stop}
                        color={props.isIdle ? 'success' : 'error'}
                        sx={{ width: '100%' }}
                        fullWidth
                    >
                        {props.isIdle ? 'Open Input Form' : <Stop />}
                    </Button>
                </>
            )}
        </div>
    );
}

export default ChatInput;