export enum ParameterType {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date',
    OBJECT = 'object',
    // ENUM = 'enum'
}

export enum ParameterRagType {
    NONE = 'N/A',
    TAG = 'Tag',
    VECTOR = 'Vec'
}

export interface ParameterDto {
    name: string;
    description: string;
    type: ParameterType;
    isRequired: boolean;
    isArray: boolean;
    ragType: ParameterRagType;
    subProperties?: ParameterDto[];
}
