import { CoreMessage } from "ai";
import { AgentDto } from "./dtos/agent.interface";
import { DataDto } from "./dtos/data.interface";
import { ParameterDto } from "./dtos/parameter.interface";
import { PromptDto } from "./dtos/prompt.interface";
import { ToolDto } from "./dtos/tool.interface";
import { User } from "./dtos/user.interface";

const baseUrl = process.env.REACT_APP_TOOLBASE_BASE_URL;

export class ApiError extends Error {
    constructor(public messages: string[]) {
        super(messages.join('\n'));
        this.name = 'ApiError';
    }
}

export interface ToolCompletionChunk {
    type: 'TOOL';
    name: string;
    dotPath: number;
    chunk: string;
}

export interface JsonCompletionChunk {
    type: 'JSON';
    dotPath: string;
    chunk: string;
}

export interface TextCompletionChunk {
    type: 'TEXT';
    chunk: string;
}

export type CompletionsChunk = ToolCompletionChunk | JsonCompletionChunk | TextCompletionChunk;

const authenticatedFetch = async (url: string, options: RequestInit & { raw?: boolean } = {}) => {
    const headers = new Headers(options.headers);
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `Bearer ${auth.getAccessToken()}`);

    if (refreshTokenPromise) await refreshTokenPromise;
    const response = await fetch(url, {
        headers,
        ...options,
    });

    if (response.status === 401) {
        console.log('Refreshing token');
        const refreshed = await auth.refresh();
        if (refreshed) {
            headers.set('Authorization', `Bearer ${auth.getAccessToken()}`);
            return handleResponse(await fetch(url, {
                headers,
                ...options,
            }), options.raw);
        }
    }

    return handleResponse(response, options.raw);
};

const handleResponse = async (response: Response, raw?: boolean) => {
    if (!response.ok) {
        let r = await response.json();
        let messages = typeof r.message !== 'string' ? r.message : [r.message];
        throw new ApiError(messages);
    }

    const contentType = response.headers.get('Content-Type');
    if (raw) {
        return response;
    } else if (contentType && contentType.includes('application/json')) {
        return await response.json();
    } else {
        return await response.text();
    }
}


const agents = {
    create: async (agent: AgentDto) => {
        return await authenticatedFetch(`${baseUrl}/agents`, {
            method: 'POST',
            body: JSON.stringify(agent)
        }) as Promise<AgentDto>;//returns the path to the new agent
    },
    list: async () => {
        return await authenticatedFetch(`${baseUrl}/agents`, {
            method: 'GET',
        }) as Promise<AgentDto[]>;
    },
    listForNamespace: async (namespace: string, from: number = 0, size: number = 20) => {
        const queryParams = new URLSearchParams({
            from: from.toString(),
            size: size.toString()
        });
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}?${queryParams}`, {
            method: 'GET',
        }) as Promise<AgentDto[]>;
    },
    listPromptVersions: async (namespace: string, name: string, from: number = 0, size: number = 20) => {
        const queryParams = new URLSearchParams({
            from: from.toString(),
            size: size.toString()
        });
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}?${queryParams}`, {
            method: 'GET',
        }) as Promise<PromptDto[]>;
    },
    getPromptVersion: async (namespace: string, name: string, version?: string) => {
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}/${version}`, {
            method: 'GET',
        }) as Promise<PromptDto>;
    },
    updatePrompt: async (namespace: string, name: string, version: string, prompt: Partial<PromptDto>) => {
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}/${version}`, {
            method: 'PUT',
            body: JSON.stringify({ prompt }, (key, value) => {
                if (value === undefined) {
                    return null;
                }
                return value;
            })
        }) as Promise<PromptDto>;
    },
    createPrompt: async (namespace: string, name: string, version: string, prompt: PromptDto) => {
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}/${version}`, {
            method: 'POST',
            body: JSON.stringify({ prompt })
        }) as Promise<PromptDto>;
    },
    deleteAgent: async (namespace: string, name: string) => {
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}`, {
            method: 'DELETE',
        }) as Promise<number>;//returns the number of agents deleted
    },
    deletePromptVersion: async (namespace: string, name: string, version?: string) => {
        const queryParams = new URLSearchParams({
            namespace,
            name,
            ...(version && { version })
        });
        return await authenticatedFetch(`${baseUrl}/agents/${namespace}/${name}/${version || 'latest'}?${queryParams}`, {
            method: 'DELETE',
        }) as Promise<number>;//returns the number of agents deleted
    },
}

const data = {
    list: async (namespace: string, agent: string, options?: {
        promptVersion?: string,
        caliber?: 'GOLD' | 'SILVER' | 'BRONZE',
        from?: number,
        size?: number,
        vectorSearchBy?: string
    }) => {
        const queryParams = new URLSearchParams({
            ...(options?.promptVersion && { agentVersion: options.promptVersion }),
            ...(options?.caliber && { caliber: options.caliber }),
            from: (options?.from ?? 0).toString(),
            size: (options?.size ?? 50).toString(),
            ...(options?.vectorSearchBy && { vectorSearchBy: options.vectorSearchBy })
        });

        return await authenticatedFetch(`${baseUrl}/data/${namespace}/${agent}?${queryParams}`, { method: 'GET' }) as Promise<DataDto[]>;
    },
    create: async (namespace: string, agent: string, promptVersion: string, data: DataDto) => {
        return await authenticatedFetch(`${baseUrl}/data/${namespace}/${agent}/${promptVersion}`, {
            method: 'POST',
            body: JSON.stringify({ data })
        }) as Promise<DataDto>;
    },
    update: async (namespace: string, agent: string, agentVersion: string, version: string, data: Partial<DataDto>) => {
        return await authenticatedFetch(`${baseUrl}/data/${namespace}/${agent}/${agentVersion}/${version}`, {
            method: 'PUT',
            body: JSON.stringify(data, (key, value) => {
                if (value === undefined) {
                    return null;
                }
                return value;
            })
        }) as Promise<DataDto>;
    },
    delete: async (namespace: string, agent: string, agentVersion: string, version: string) => {
        return await authenticatedFetch(`${baseUrl}/data/${namespace}/${agent}/${agentVersion}/${version}`, {
            method: 'DELETE'
        }) as Promise<DataDto>;
    },
}

let refreshTokenPromise: Promise<User> | undefined;

const auth = {
    isLoggedIn: () => {
        return !!localStorage.getItem('accessToken') && !!localStorage.getItem('user');
    },
    getUser: () => {
        const userString = localStorage.getItem('user');
        return userString ? JSON.parse(userString) as User : undefined;
    },
    getAccessToken: () => {
        return localStorage.getItem('accessToken') ?? undefined;
    },
    login: async (email: string, password: string) => {
        return await fetch(`${baseUrl}/auth/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password })
            }).then(handleResponse).then(response => {
                let { accessToken, refreshToken, ...user } = response;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                return user;
            }) as Promise<User>;
    },
    register: async (user: User, password: string) => {
        return await fetch(`${baseUrl}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...user, password })
        }).then(handleResponse).then(response => {
            localStorage.setItem('user', JSON.stringify(response));
            return response;
        }) as Promise<User>;
    },
    refresh: async () => {
        if (refreshTokenPromise) {
            return refreshTokenPromise;
        }

        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            throw new Error('Session not found. Please login again.');
        }

        return refreshTokenPromise = fetch(`${baseUrl}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken })
        }).then(handleResponse).then(response => {
            let { accessToken, refreshToken, ...user } = response;
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);

            return response;
        }).catch(error => {
            auth.logout();
            throw error;
        }).finally(() => {
            refreshTokenPromise = undefined;
        }) as Promise<User>;
    },
    logout: () => {
        localStorage.removeItem('user');
        //what else needed to log out? would likely need to remove tokens from backend
    },
    generateApiToken: async (email: string, password: string) => {
        return await authenticatedFetch(`${baseUrl}/auth/generate-api-token`,
            {
                method: 'POST',
                body: JSON.stringify({ email, password })
            }).then(response => {
                let { apiToken } = response;
                return apiToken;
            }) as Promise<string>;
    },
}

const tools = {
    create: async (namespace: string, name: string, tool: ToolDto) => {
        return await authenticatedFetch(`${baseUrl}/tools/${namespace}/${name}`, {
            method: 'POST',
            body: JSON.stringify({ tool })
        }) as Promise<ToolDto>;
    },
    list: async (from: number = 0, size: number = 25) => {
        const queryParams = new URLSearchParams({
            from: from.toString(),
            size: size.toString()
        });
        return await authenticatedFetch(`${baseUrl}/tools?${queryParams}`, {
            method: 'GET',
        }) as Promise<ToolDto[]>;
    },
    listForNamespace: async (namespace: string, from: number = 0, size: number = 25) => {
        const queryParams = new URLSearchParams({
            from: from.toString(),
            size: size.toString()
        });
        return await authenticatedFetch(`${baseUrl}/tools/${namespace}?${queryParams}`, {
            method: 'GET',
        }) as Promise<ToolDto[]>;
    },
    getTool: async (namespace: string, name: string, version?: string) => {
        const queryParams = new URLSearchParams();
        if (version) {
            queryParams.append('version', version);
        }
        const url = `${baseUrl}/tools/${namespace}/${name}${queryParams.toString() ? '?' + queryParams.toString() : ''}`;
        return await authenticatedFetch(url, {
            method: 'GET',
        }) as Promise<ToolDto>;
    },
    updateTool: async (namespace: string, name: string, version: string, tool: Partial<ToolDto>) => {
        return await authenticatedFetch(`${baseUrl}/tools/${namespace}/${name}/${version}`, {
            method: 'PUT',
            body: JSON.stringify({ tool }, (key, value) => {
                if (value === undefined) {
                    return null;
                }
                return value;
            })
        }) as Promise<ToolDto>;
    },
    deleteTool: async (namespace: string, name: string, version: string) => {
        return await authenticatedFetch(`${baseUrl}/tools/${namespace}/${name}/${version}`, {
            method: 'DELETE',
        }) as Promise<ToolDto>;
    },
    execute: async (namespace: string, name: string, messages: CoreMessage[], toolVersion?: string) => {
        console.log(messages);
        const response: Response = await authenticatedFetch(`${baseUrl}/tools/${namespace}/${name}/${toolVersion ?? 'latest'}`, {
            method: 'POST',
            body: JSON.stringify({ messages }),
            raw: true
        });

        const type: 'TEXT' | 'JSON' | 'TOOL' = response.headers.get('Content-Type')?.includes('application/json') ? 'JSON' : response.headers.get('Content-Type')?.includes('text/plain') ? 'TEXT' : 'TOOL';
        const reader = response.body?.getReader();

        if (reader) {
            return {
                [Symbol.asyncIterator]: async function* () {
                    const decoder = new TextDecoder('utf-8');
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        let chunk = decoder.decode(value, { stream: true });
                        yield chunk;
                    }
                }, cancel: () => reader.cancel(),
                type
            };
        } else {
            throw new ApiError(['Failed to read response']);
        }
    }
}

const util = {
    convertParametersToCode: async (name: string, language: 'typescript' | 'python', parameters: ParameterDto[]) => {
        return await authenticatedFetch(`${baseUrl}/util/convert-parameters-to-code`, {
            method: 'POST',
            body: JSON.stringify({ name, language, parameters })
        }) as Promise<string>;
    }
}

const chat = {
    completions: async (namespace: string, name: string, messages: CoreMessage[], version: string = 'latest', vectorSearch: boolean = true, skipValidateInput: boolean = false) => {
        const response: Response = await authenticatedFetch(`${baseUrl}/chat/${namespace}/${name}/${version}`, {
            method: 'POST',
            body: JSON.stringify({ messages, vectorSearch, skipValidateInput }),
            raw: true
        });

        const type: 'TEXT' | 'JSON' | 'TOOL' = response.headers.get('Content-Type')?.includes('application/json') ? 'JSON' : response.headers.get('Content-Type')?.includes('text/plain') ? 'TEXT' : 'TOOL';
        const dataVersion = response.headers.get('Content-Type')!.substring(response.headers.get('Content-Type')!.indexOf(';') + 1);

        const reader = response.body?.getReader();

        if (reader) {
            return {
                dataVersion,
                [Symbol.asyncIterator]: async function* () {
                    const decoder = new TextDecoder('utf-8');
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        let chunk = decoder.decode(value, { stream: true });
                        yield chunk;
                    }
                }, cancel: () => reader.cancel(),
                type
            };
        } else {
            throw new ApiError(['Failed to read response']);
        }
    }
}


export const ToolbaseApi = {
    agents,
    auth,
    data,
    tools,
    util,
    chat
}
