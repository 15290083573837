import { JSONSchema7 } from "json-schema";
import { ParameterDto, ParameterRagType, ParameterType } from "./dtos/parameter.interface";

const parametersToJSONSchema = (schema: JSONSchema7, parameters: ParameterDto[]) => {
    parameters.forEach((param: ParameterDto) => {
        schema.required = schema.required ?? [];
        if (param.isRequired) {
            schema.required.push(param.name);
        }

        schema.properties = schema.properties ?? {};
        schema.properties[param.name] = {
            type: param.type === 'date' ? 'string' : param.type,
            description: param.description
        };

        if (param.isArray) {
            (schema.properties[param.name] as JSONSchema7).type = "array";
            (schema.properties[param.name] as JSONSchema7).items = {
                type: param.type === 'date' ? 'string' : param.type,
            };

            if (param.type === 'date') {
                ((schema.properties[param.name] as JSONSchema7).items as JSONSchema7).format = 'date-time';
            } else if (param.type === "object" && param.subProperties) {
                (schema.properties[param.name] as JSONSchema7).items = parametersToJSONSchema((schema.properties[param.name] as JSONSchema7).items as JSONSchema7, param.subProperties);
            }
        } else if (param.type === 'date') {
            (schema.properties[param.name] as JSONSchema7).format = 'date-time';
        } else if (param.type === "object" && param.subProperties) {
            schema.properties[param.name] = parametersToJSONSchema(schema.properties[param.name] as JSONSchema7, param.subProperties);
        }

    });

    if (schema.required?.length === 0) {
        delete schema.required;
    }

    return schema;
};

const parametersFromJSONSchema = (schema: JSONSchema7): ParameterDto[] => {
    if (schema.type === 'object' || (schema.type === 'array' && schema.items && (schema.items as JSONSchema7).type === 'object')) {
        return Object.entries(schema.properties ?? (schema.items as JSONSchema7)?.properties ?? {}).map(([name, property]) => {
            let type = (property as JSONSchema7).type === 'array' ? ((property as JSONSchema7).items as JSONSchema7)?.type as ParameterType : (property as JSONSchema7).type as ParameterType;

            if ((type as any) === 'integer') {
                type = ParameterType.NUMBER;
            } else if (type === 'string' && (property as JSONSchema7).format === 'date-time') {
                type = ParameterType.DATE;
            }

            return {
                name,
                description: (property as JSONSchema7).description ?? '',
                type,
                isRequired: schema.type === 'array' ? ((schema.items as JSONSchema7).required?.includes(name) ?? false) : (schema.required?.includes(name) ?? false),
                ragType: ParameterRagType.NONE,
                isArray: (property as JSONSchema7).type === 'array',
                subProperties: (property as JSONSchema7).type === 'object' || ((property as JSONSchema7).type === 'array' && ((property as JSONSchema7).items as JSONSchema7)?.type === 'object') ? parametersFromJSONSchema(property as JSONSchema7) : undefined
            };
        });
    } else {
        console.error(schema);
        throw new Error('Invalid schema');
    }
}

export const ParameterUtil = {
    parametersToJSONSchema: (parameters?: ParameterDto[]) => {
        return parameters && parameters.length > 0 ? parametersToJSONSchema({
            type: "object",
        }, parameters) : undefined;
    },
    parametersFromJSONSchema
};