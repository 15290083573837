import { StyledComponent } from "@emotion/styled";
import { Add, ChevronLeft, ChevronRight, SmartToy } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Component, ReactNode } from "react";

import { AgentDto } from "../api/dtos/agent.interface";
import { ApiError, ToolbaseApi } from "../api/toolbase.api";
import NewAgentDialog from "./new-agent-dialog";

type AgentsListState = {
    readonly agents: AgentDto[];
    readonly isLoading: boolean;
    readonly isOpen: boolean;
    readonly newAgentDialog: boolean;
}

type AgentsListProps = {
    readonly isCompacted: boolean;
    readonly drawerHeader: StyledComponent<any, any>;
    readonly width: number;
    // readonly navigate: NavigateFunction;
}

export class AgentsListItem extends Component<AgentsListProps, AgentsListState> {

    constructor(readonly props: AgentsListProps, readonly state: AgentsListState) {
        super(props);
        this.state = {
            agents: [],
            isLoading: true,
            isOpen: false,
            newAgentDialog: false
        };
    }

    componentDidMount(): void {
        this.loadAgents();
    }

    newAgent() {
        this.setState(old => ({ ...old, newAgentDialog: true }));
    }

    async loadAgents() {
        this.setState(old => ({ ...old, isLoading: true }));
        try {
            let agents: AgentDto[] = await ToolbaseApi.agents.list();
            this.setState(old => ({ ...old, agents, isLoading: false }));
        } catch (error) {
            console.error("Failed to load agents:", error);
            this.setState(old => ({ ...old, isLoading: false }));
        }
    }

    toggleDrawer = (state: boolean) => () => {
        this.setState(old => ({ ...old, isOpen: state }));
    };

    async navigateTo(name?: string) {
        if (!name) {
            window.location.reload();
        } else {
            try {
                let agent = await ToolbaseApi.agents.create({
                    system: {
                        prompt: "Respond to the user in a friendly and helpful manner. Remind them that they must configure the prompt before it can be used.",
                        inputParameters: undefined,
                        outputParameters: undefined,
                        agentNamespace: ToolbaseApi.auth.getUser()?.email ?? 'Toolbase',
                        agentName: name,
                        summary: "Requires configuration.",
                        modelProvider: "openai",
                        modelId: "gpt-4o-mini",
                        //                         outputMessageTemplate: `
                        // async function assertOrTransform(user: User, input: Input, output: Output, similarExamples: SimilarExample[]): Promise<Output> {
                        //     //add your code here
                        //     return output; 
                        // }

                        //                                         `.trim()
                    },
                    namespace: ToolbaseApi.auth.getUser()?.email ?? 'Toolbase',
                    name,
                });
                window.location.href = `/agents/${agent.namespace}/${agent.name}/${agent.__version ?? 'latest'}`;
            } catch (error) {
                alert((error instanceof ApiError ? error.messages.join('\n') : error));
            }
        }
    }

    render(): ReactNode {
        const DrawerHeader = this.props.drawerHeader;
        if (this.state.newAgentDialog) {
            return (<NewAgentDialog onClose={this.navigateTo} />);
        }
        return (
            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton onClick={this.toggleDrawer(true)}
                    sx={{
                        minHeight: 48,
                        justifyContent: !this.props.isCompacted ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: !this.props.isCompacted ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <SmartToy />
                    </ListItemIcon>
                    <ListItemText primary="Agents" sx={{ opacity: !this.props.isCompacted ? 1 : 0 }} />
                </ListItemButton>
                {this.state.newAgentDialog ? (<NewAgentDialog onClose={this.navigateTo.bind(this)} />) : undefined}
                <Drawer open={this.state.isOpen} onClose={this.toggleDrawer(false)}>
                    <DrawerHeader>
                        <Typography variant="h6" noWrap component="div" sx={{ width: '100%' }}>
                            Agents
                        </Typography>
                        <IconButton onClick={this.toggleDrawer(false)}>
                            <ChevronLeft />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <Box sx={{ width: this.props.width }} role="presentation" onClick={this.toggleDrawer(false)}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={this.newAgent.bind(this)}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText primary="New..." />
                                </ListItemButton>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            {this.state.agents.map((a, i) => (
                                <ListItem key={i} disablePadding>
                                    <ListItemButton href={`/agents/${a.namespace}/${a.name}/${a.__version ?? ''}`}>
                                        <ListItemText
                                            primary={a.name}
                                            sx={{
                                                width: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }} />
                                        <ChevronRight />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </ListItem>
        );
    }
}

// export function AgentsListItemWrapper(props: Omit<AgentsListProps, 'navigate'>) {
//     const navigate = useNavigate();
//     return <AgentsListItem {...props} navigate={navigate} />;
// }