import React, { useCallback, useMemo, useRef, useState } from 'react';

import { DiffEditor, Editor } from '@monaco-editor/react';
import { AltRoute, AutoFixNormal, Delete, Refresh, Save } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { CoreMessage } from 'ai';
import * as monaco from 'monaco-editor';
import { PromptDto } from '../../api/dtos/prompt.interface';
import { ToolbaseApi } from '../../api/toolbase.api';
import { ParameterUtil } from '../../api/util';
import ParameterEditor from './parameter-editor';

interface PromptEditorProps {
  prompt: PromptDto;
  display: 'system' | 'input' | 'output';
  comparePrompt?: PromptDto;
  onFork: () => void;
  onDelete: () => void;
  onChange: (prompt: Partial<PromptDto>) => void;
  onSave: () => void;
}

const PromptEditor: React.FC<PromptEditorProps> = React.memo((props) => {
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, text: '', autoFocus: false, coreMessages: [] as CoreMessage[] });

  const [inputParamsDialogOpen, setInputParamsDialogOpen] = useState(false);
  const [outputParamsDialogOpen, setOutputParamsDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<typeof monaco>(monaco);
  const saveRef = useRef<typeof props.onSave>(props.onSave);
  const displayRef = useRef<typeof props.display>(props.display);

  const promptRef = useRef(props.prompt);

  const modelProviderRef = useRef<HTMLInputElement>(null);
  const modelIdRef = useRef<HTMLInputElement>(null);
  const temperatureRef = useRef<HTMLInputElement>(null);
  const seedRef = useRef<HTMLInputElement>(null);

  const editorLanguage = useMemo(() => {
    return props.display === 'output' ? props.prompt.onAfter?.language ?? 'typescript' : props.display === 'input' ? props.prompt.onBefore?.language ?? 'handlebars' : 'plaintext';
  }, [props.display, props.prompt.onAfter?.language, props.prompt.onBefore?.language]);

  const afterParameterChange = useCallback(async () => {
    try {
      const [outputCode, inputCode] = await Promise.all([
        promptRef.current.outputParameters ? promptRef.current.outputParameters.length > 0 ? ToolbaseApi.util.convertParametersToCode('Output', 'typescript', promptRef.current.outputParameters) : Promise.resolve('export type Output = {\n\t[property: string]: any;\n}\n') : Promise.resolve('export type Output = string;'),
        promptRef.current.inputParameters ? promptRef.current.inputParameters.length > 0 ? ToolbaseApi.util.convertParametersToCode('Input', 'typescript', promptRef.current.inputParameters) : Promise.resolve('export type Input = {\n\t[property: string]: any;\n}\n') : Promise.resolve('export type Input = string;')
      ]);

      monacoRef.current.languages.typescript.typescriptDefaults.setExtraLibs([{
        content: `
        ${inputCode.trim().replaceAll('export type', 'type').replaceAll('    [property: string]: any;\n', '')}
        ${outputCode.trim().replaceAll('export type', 'type').replaceAll('    [property: string]: any;\n', '')}
        declare function assert(condition: any, msg?: string): typeof console.assert;
        declare type User = {
            name: string;
            email: string;
            phone?: string;
        }
        declare type SimilarExample = {
            similarityScore: number;
            tags: string[];
            caliber: "GOLD" | "SILVER" | "BRONZE";
            inputData: Partial<Input> & {[property: string]: any};
            outputData: Partial<Output> & {[property: string]: any};
        }
        declare const user: User;
        declare const input: Input;
        declare const output: Output;
        declare const similarExamples: SimilarExample[];
        `,
        filePath: 'ts:filename/global.d.ts'
      }]);

    } catch (error) {
      alert('Error generating parameter definitions for code completion: ' + error);
    }
  }, []);

  React.useEffect(() => {
    const handleSaveShortcut = async (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault();
        saveRef.current?.();
      }
    };

    document.addEventListener('keydown', handleSaveShortcut);

    return () => document.removeEventListener('keydown', handleSaveShortcut);

  }, [props.prompt.agentNamespace, props.prompt.agentName]);

  React.useEffect(() => {
    saveRef.current = props.onSave;
    promptRef.current = props.prompt;
  }, [props.prompt.__version]);

  React.useEffect(() => {
    displayRef.current = props.display;
    if (props.display === 'system') {
      editorRef.current?.setValue(props.prompt.prompt);
    } else if (props.display === 'input') {
      editorRef.current?.setValue(props.prompt.inputMessageTemplate ?? '');
    } else if (props.display === 'output') {
      editorRef.current?.setValue(props.prompt.outputMessageTemplate ?? '');
    }
  }, [props.prompt.__version, props.display]);

  const onEditorMount = useCallback((editor: monaco.editor.IStandaloneCodeEditor, m: typeof monaco) => {
    editorRef.current = editor;
    monacoRef.current = m;

    // m.languages.registerInlineCompletionsProvider('handlebars', {
    //   freeInlineCompletions: (completions) => {
    //     console.log(completions);
    //   },
    //   provideInlineCompletions: function (model: monaco.editor.ITextModel, position: monaco.Position, context: monaco.languages.InlineCompletionContext, token: monaco.CancellationToken): monaco.languages.ProviderResult<monaco.languages.InlineCompletions<monaco.languages.InlineCompletion>> {
    //     console.log(model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column }));
    //     return {
    //       items: [{
    //         insertText: 'test',

    //       }]
    //     };
    //   }
    // });

    // m.languages.registerCompletionItemProvider('handlebars', {
    //   provideCompletionItems: (model, position, context, token) => {
    //     const suggestions: monaco.languages.CompletionItem[] = []
    //     const textUntilPosition = model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column })
    //     const textAfterPosition = model.getValueInRange({ startLineNumber: position.lineNumber, startColumn: position.column, endLineNumber: position.lineNumber, endColumn: model.getLineMaxColumn(position.lineNumber) })
    //     const range = {
    //       startLineNumber: position.lineNumber,
    //       endLineNumber: position.lineNumber,
    //       startColumn: textUntilPosition.lastIndexOf('{{'),
    //       endColumn: position.column + (textAfterPosition.trim().indexOf('}}') === 0 ? textAfterPosition.trim().indexOf('}}') + 2 : 0)
    //     }

    //     console.log(range.startColumn);
    //     // Match handlebars opening delimiter
    //     if (range.startColumn >= 0) {
    //       for (const snippet of ['name', 'email', 'phone']) {
    //         // Push handlebars snippets
    //         suggestions.push(
    //           {
    //             label: snippet,
    //             kind: m.languages.CompletionItemKind.Variable,
    //             insertText: `{{ ${snippet} }}`,
    //             range: range,
    //           })
    //       }
    //       return { suggestions }
    //     }

    //     return { suggestions };
    //   }
    // });

    afterParameterChange();

    if (displayRef.current === 'system') {
      editor.setValue(props.prompt.prompt);
    } else if (displayRef.current === 'input') {
      editor.setValue(props.prompt.inputMessageTemplate ?? '');
    } else if (displayRef.current === 'output') {
      editor.setValue(props.prompt.outputMessageTemplate ?? '');
    }
  }, [props.prompt.prompt, props.prompt.inputMessageTemplate, props.prompt.outputMessageTemplate]);

  const onDiffEditorMount = useCallback((editor: monaco.editor.IStandaloneDiffEditor, m: typeof monaco) => {
    editorRef.current = editor.getModifiedEditor();
    monacoRef.current = m;

    afterParameterChange();

    if (displayRef.current === 'system') {
      editor.getModifiedEditor().setValue(props.prompt.prompt);
    } else if (displayRef.current === 'input') {
      editor.getModifiedEditor().setValue(props.prompt.inputMessageTemplate ?? '');
    } else if (displayRef.current === 'output') {
      editor.getModifiedEditor().setValue(props.prompt.outputMessageTemplate ?? '');
    }

    editor.onDidUpdateDiff(() => {
      if (displayRef.current === 'system') {
        if (editorRef.current?.getValue() !== props.prompt.prompt) {
          props.onChange({ prompt: editor.getModifiedEditor().getValue() });
        }
      } else if (displayRef.current === 'input') {
        console.log('input', editorRef.current?.getValue(), props.prompt.inputMessageTemplate);
        if (editorRef.current?.getValue().length === 0 && (props.prompt.inputMessageTemplate?.length ?? 0) === 0) return;
        if (editorRef.current?.getValue() !== props.prompt.inputMessageTemplate) {
          props.onChange({ inputMessageTemplate: editor.getModifiedEditor().getValue() });
        }
      } else if (displayRef.current === 'output') {
        if (editorRef.current?.getValue().length === 0 && (props.prompt.outputMessageTemplate?.length ?? 0) === 0) return;
        if (editorRef.current?.getValue() !== props.prompt.outputMessageTemplate) {
          props.onChange({ outputMessageTemplate: editor.getModifiedEditor().getValue() });
        }
      }
    });
  }, [props.prompt.prompt, props.prompt.inputMessageTemplate, props.prompt.outputMessageTemplate, props.onChange]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <Box sx={{ flexShrink: 0 }}>
        <Box sx={{ display: 'flex', gap: 2, p: 2, justifyContent: 'flex-end' }}>

          <ButtonGroup sx={{ marginRight: 'auto', marginLeft: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={props.display !== 'system'}
              onClick={async () => {
                try {
                  document.body.style.pointerEvents = 'none';
                  let request: { [keyof: string]: any } = {}
                  request.taskGoalOrPrompt = editorRef.current?.getValue();

                  if (promptRef.current.inputParameters && promptRef.current.inputParameters.length > 0) {
                    request.inputParameters = JSON.stringify(ParameterUtil.parametersToJSONSchema(promptRef.current.inputParameters));
                  }

                  if (promptRef.current.outputParameters && promptRef.current.outputParameters.length > 0) {
                    request.outputParameters = JSON.stringify(ParameterUtil.parametersToJSONSchema(promptRef.current.outputParameters));
                  }

                  request.fewShotExamples = (await ToolbaseApi.data.list(props.prompt.agentNamespace, props.prompt.agentName, { caliber: 'GOLD', from: 0, size: 15 })).map(d => ({
                    input: d.inputData,
                    output: d.outputData,
                    reasoning: d.reasoning.length > 0 ? d.reasoning : undefined
                  }));

                  let stream = await ToolbaseApi.chat.completions(ToolbaseApi.auth.getUser()!.email, 'Prompt Generator', [{ role: 'user', content: JSON.stringify(request) }], 'generate');
                  editorRef.current?.getModel()?.setValue('');
                  for await (const chunk of stream!) {
                    editorRef.current?.getModel()?.setValue(editorRef.current?.getModel()?.getValue() + chunk);
                  }
                } finally {
                  document.body.style.pointerEvents = 'auto';
                }
              }}
            >
              <Refresh />
            </Button>

            <Button
              variant="contained"
              color="success"
              disabled={props.display !== 'system'}
              onClick={async () => {
                try {
                  let taskOrGoal = window.prompt('Enter the task or goal you want to refine the prompt for:');
                  if (!taskOrGoal) {
                    return;
                  }
                  document.body.style.pointerEvents = 'none';
                  let request: { [keyof: string]: any } = { taskOrGoal, currentPrompt: editorRef.current?.getValue() };

                  let r = '';
                  let stream = await ToolbaseApi.chat.completions(ToolbaseApi.auth.getUser()!.email, 'Prompt Generator', [{ role: 'user', content: JSON.stringify(request) }], 'refine', true);
                  editorRef.current?.getModel()?.setValue('');
                  for await (const chunk of stream!) {
                    r += chunk;
                    if (r.startsWith('<reasoning>') || r.length < 12) {
                      if (r.indexOf('</reasoning>') !== -1) {
                        r = r.substring(r.indexOf('</reasoning>') + 12).trim();
                      }
                    } else {
                      editorRef.current?.getModel()?.setValue(r);
                    }
                  }
                } finally {
                  document.body.style.pointerEvents = 'auto';
                }
              }}
            >
              <AutoFixNormal />
            </Button>
          </ButtonGroup>

          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            {props.display === 'system' && (
              <Button
                variant="contained"
                onClick={() => setSettingsDialogOpen(true)}
                sx={{ width: 160 }}
              >
                Prompt Settings
              </Button>
            )}
            {props.display === 'input' && (
              <ButtonGroup>
                <Button
                  variant="contained"
                  sx={{ width: 160 }}
                  onClick={() => setInputParamsDialogOpen(true)}
                >
                  Input Parameters
                </Button>

              </ButtonGroup>
            )}
            {props.display === 'output' && (
              <ButtonGroup>
                <Button
                  variant="contained"
                  sx={{ width: 160 }}
                  onClick={() => setOutputParamsDialogOpen(true)}
                >
                  Output Parameters
                </Button>
              </ButtonGroup>
            )}
          </Box>


          <ButtonGroup>
            <Button
              variant="contained"
              color="error"
              onClick={props.onDelete}
            >
              <Delete />
            </Button>

            <Button
              variant="contained"
              onClick={props.onFork}
            >
              <AltRoute />
            </Button>

            <Button
              variant="contained"
              color="info"
              onClick={props.onSave}
            >
              <Save />
            </Button>
          </ButtonGroup>

          {inputParamsDialogOpen && <ParameterEditor
            initialParameters={promptRef.current.inputParameters}
            isInput={true}
            onClose={(params) => {
              setInputParamsDialogOpen(false);
              if (params !== promptRef.current.inputParameters) {
                promptRef.current.inputParameters = params;
                props.onChange({ inputParameters: params });
                afterParameterChange();
              }
            }} />}

          {outputParamsDialogOpen && <ParameterEditor
            initialParameters={promptRef.current.outputParameters}
            isInput={false}
            onClose={(params) => {
              setOutputParamsDialogOpen(false);
              if (params !== promptRef.current.outputParameters) {
                promptRef.current.outputParameters = params;
                props.onChange({ outputParameters: params });
                afterParameterChange();
              }
            }} />}

          <Dialog
            open={settingsDialogOpen}
            onClose={() => setSettingsDialogOpen(false)}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Prompt Settings</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                margin="normal"
                label="Model Provider"
                ref={modelProviderRef}
                defaultValue={promptRef.current.modelProvider}
                onChange={(e) => {
                  promptRef.current.modelProvider = e.target.value;
                  props.onChange({ modelProvider: e.target.value });
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Model ID"
                ref={modelIdRef}
                defaultValue={promptRef.current.modelId}
                onChange={(e) => {
                  promptRef.current.modelId = e.target.value;
                  props.onChange({ modelId: e.target.value });
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Temperature"
                type="number"
                ref={temperatureRef}
                defaultValue={promptRef.current.temperature?.toString()}
                onChange={(e) => {
                  promptRef.current.temperature = Number(e.target.value);
                  props.onChange({ temperature: Number(e.target.value) });
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Seed"
                type="number"
                ref={seedRef}
                defaultValue={promptRef.current.seed?.toString()}
                onChange={(e) => {
                  promptRef.current.seed = Number(e.target.value);
                  props.onChange({ seed: Number(e.target.value) });
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSettingsDialogOpen(false)}>Close</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        {props.comparePrompt ? (
          <DiffEditor
            className='monaco-editor'
            height="100%"
            language={editorLanguage}
            original={props.display === 'system' ? props.comparePrompt.prompt :
              props.display === 'input' ? props.comparePrompt.inputMessageTemplate ?? '' :
                props.comparePrompt.outputMessageTemplate ?? ''
            }
            modified={props.display === 'system' ? props.prompt.prompt :
              props.display === 'input' ? props.prompt.inputMessageTemplate ?? '' :
                props.prompt.outputMessageTemplate ?? ''
            }
            onMount={onDiffEditorMount}
            options={{
              quickSuggestions: editorLanguage === 'typescript',
              parameterHints: {
                enabled: true
              },
              renderSideBySide: false, //props.comparePrompt.__version !== props.prompt.__version,
              minimap: {
                enabled: false,
              },

              scrollbar: {
                vertical: 'auto',
                horizontal: 'auto',
              },
            }}
          />
        ) : (
          <Editor
            className='monaco-editor'
            height="100%"
            language={editorLanguage}
            onMount={onEditorMount}
            onChange={(value) => {
              if (displayRef.current === 'system') {
                if (value !== props.prompt.prompt) {
                  props.onChange({ prompt: value });
                }
              } else if (displayRef.current === 'input') {
                if (editorRef.current?.getValue().length === 0 && (props.prompt.inputMessageTemplate?.length ?? 0) === 0) return;
                if (value !== props.prompt.inputMessageTemplate) {
                  props.onChange({ inputMessageTemplate: value });
                }
              } else if (displayRef.current === 'output') {
                if (editorRef.current?.getValue().length === 0 && (props.prompt.outputMessageTemplate?.length ?? 0) === 0) return;
                if (value !== props.prompt.outputMessageTemplate) {
                  props.onChange({ outputMessageTemplate: value });
                }
              }
            }}
            options={{
              quickSuggestions: true,//editorLanguage === 'typescript',
              parameterHints: {
                enabled: true
              },
              minimap: {
                enabled: false,
              },
              scrollbar: {
                vertical: 'auto',
                horizontal: 'auto',
              },
            }}
          />
        )}
        {tooltip.visible && (
          <div
            style={{
              position: 'absolute',
              top: tooltip.y,
              left: tooltip.x,
            }}>
            {/* <ChatInput
              autoFocus={tooltip.autoFocus}
              isIdle={true}
              onNewMessage={(message: string | { [key: string]: any }) => {
                setTooltip((prevTooltip) => ({
                  ...prevTooltip,
                  visible: false,
                }));
                // generatePrompt(typeof message === 'string' ? message : JSON.stringify(message), tooltip.text);
              }}
              onStop={async () => {
                throw new Error('not implemented');
              }}
            /> */}
          </div>
        )}
        <Refresh className='stream-icon' style={{
          position: 'absolute',
          right: 16,
          bottom: 2,
          zIndex: 1000, // Ensure it's on top of other elements
          display: 'none',
        }} />
      </Box>
    </Box >
  );
});

export default PromptEditor;
