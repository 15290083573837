import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from 'react';

export default function NewAgentDialog(props: { onClose: (name?: string) => void }) {

    // const handleClose = (name?: string) => props.onClose(name);

    const cls = (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {
        props.onClose(undefined);
    }

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={cls}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const name = formJson.name;
                        props.onClose(name);
                    },
                }}
            >
                <DialogTitle>New Agent</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter a name for the new Agent.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Agent name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cls}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}